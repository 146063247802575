import { IonApp, setupIonicReact, isPlatform, IonModal } from '@ionic/react';
import { App as CapApp } from '@capacitor/app';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Import Swiper styles  */
//import 'swiper/swiper.scss';
//import  'swiper/css/swiper.css'
/* Theme variables */
import './theme/variables.css';
/* General App styling */
import './App.scss';
import './Typography.scss';
import './theme/common.scss';
import './theme/buttons.scss';
import './theme/industry-targeting.scss';
import { AppNavigation } from "./components/Navigation/AppNavigation";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ApiResourcesContentLanguage, ApiUser, FeatureConfig, User } from "./utils/ApiTypes";
import { ApplicationContext, ApplicationContextType } from "./misc/ApplicationContext";
import { JourneyApiClient } from "./utils/JourneyApiClient";
import { capitalizeFirstLetter, MOBILE_MENU_TRESHOLD } from "./utils/utils";
import GlobalMessage from "./components/GlobalMessage/GlobalMessage";
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from "@sentry/react";
import AnalyticsService from "./misc/AnalyticsService";
import { DownloadMobileApp } from "./components/DownloadMobileApp/DownloadMobileApp";
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { PleaseWait } from './components/PleaseWait/PleaseWait';
import EnvironmentSwitcherService from './misc/EnvironmentSwitcherService';
import { t } from 'i18next';
import { LoginModalComponent } from './components/LoginModalComponent/LoginModalComponent';
import useIsComponentVisible from './components/CustomHooks/useIsComponentVisible';
import { Browser } from '@capacitor/browser';

const isMobileApp = (isPlatform("ios") || isPlatform("android")) && isPlatform("mobile") && !isPlatform("mobileweb");
setupIonicReact({ mode: "ios", swipeBackEnabled: isMobileApp });


const App: React.FC = () => {

    const { i18n } = useTranslation();
    const rafId = useRef<number | null>(null);

    const [currentUser, setCurrentUser] = useState<ApiUser | null>(null);

    // The current user may be re-loaded multiple times during the app's lifecycle.
    const [isCurrentUserLoading, setCurrentUserLoading] = useState<boolean>(true);

    const [isAppLoaded, setIsAppLoaded] = useState<boolean>(false);
    const [isMobileWidth, setIsMobileWidth] = useState<boolean>(true);
    const [isMobileMenu, setIsMobileMenu] = useState<boolean>(true);
    const [appResumeCounter, setAppResumeCounter] = useState<number>(0);
    const [globalHeader, setGlobalHeader] = useState<string | null>(null);
    const [globalMessage, setGlobalMessage] = useState<string | null>(null);
    const [language, setLanguage] = useState<ApiResourcesContentLanguage | null>(null);
    const [fullLanguageSupport, setFullLanguageSupport] = useState<boolean>(true);
    const [isSuccessMessage, setIsSuccessMessage] = useState<boolean>(false);
    const [isMindfulBreakModalOpen, setIsMindfulBreakModalOpen] = useState<boolean>(false);
    const [isDownloadMobileAppModalOpen, setIsDownloadMobileAppModalOpen] = useState<boolean>(false);
    const [isShareRegistrationLinkOpen, setIsShareRegistrationLinkOpen] = useState<boolean>(false);
    const [isInstantHelpSliderOpen, setIsInstantHelpSliderOpen] = useState<boolean>(false);
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState<boolean>(false);
    const [isLanguagesMenuOpen, setIsLanguagesMenuOpen] = useState<boolean>(false);
    const [isOldLanguagesMenuOpen, setIsOldLanguagesMenuOpen] = useState<boolean>(false);
    const [isMiniLanguageMenuOpen, setIsMiniLanguageMenuOpen] = useState<boolean>(false);
    const [isCountriesMenuOpen, setIsCountriesMenuOpen] = useState<boolean>(false);
    const { isAuthenticated, getAccessTokenSilently, logout, handleRedirectCallback } = useAuth0();
    const [totalProfileSteps, setTotalProfileSteps] = useState<number>(2);
    const [profileStepsCompleted, setProfileStepsCompleted] = useState<number>(0);
    const [hasCompletedAssessment, setHasCompletedAssessment] = useState<boolean>(true);
    const [currentlyPlayingId, setCurrentlyPlayingId] = useState<string>('');
    const [isResetInProgress, setIsResetInProgress] = useState<boolean>(false);
    const [hasDeveloperRole, setHasDeveloperRole] = useState<boolean>(false);
    const [needProfileRefresh, setNeedProfileRefresh] = useState<boolean>(false);
    const [featureConfig, setFeatureConfig] = useState<FeatureConfig>({ features: {} });
    const [isCommunicationsOptInModalOpen, setIsCommunicationsOptInModalOpen] = useState<boolean>(false);
    const [subscribeToDailyJourney, setSubscribeToDailyJourney] = useState<boolean>(false);
    const [industryCssClass, setIndustryCssClass] = useState<string>("standard");
    const isUserSales = (currentUser && currentUser.userRoles.indexOf('Sales') > -1)
    const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
    const isComponentVisible = useIsComponentVisible();
    const [hasBenefitsInThisLocale, setHasBenefitsInThisLocale] = useState<boolean>(false);
    const [hasDailyJourneyAvailable, setHasDailyJourneyAvailable] = useState<boolean>(false);
    const [hasCompanyCountries, setHasCompanyCountries] = useState<boolean>(false);
    const [hasWpoContent, setHasWpoContent] = useState<boolean>(false);
    //profile steps
    const [hasTestimonials, setHasTestimonials] = useState<boolean>(false);
    const [hasSlack, setHasSlack] = useState<boolean>(false);
    const [hasZoom, setHasZoom] = useState<boolean>(false);
    const [hasTeams, setHasTeams] = useState<boolean>(false);

    async function checkIfUserHasMobileApp() {
        if (isMobileApp && !currentUser?.hasMobileAppInstalled) {
            try {
                await JourneyApiClient.setUserHasMobileApp();
                setUserFlags("hasMobileAppInstalled");
            } catch (e) { }
        }
    }


    function handleResize() {
        if (rafId.current) return;
        rafId.current = requestAnimationFrame(updateDimensions);
    }

    useEffect(() => {

        (async function () {
            if (currentUser) {
                let totalSteps = 1; // calendar is always present
                if (!isMobileApp) { totalSteps = totalSteps + 1; }
                if(hasSlack) { totalSteps = totalSteps + 1; }
                if(hasZoom) { totalSteps = totalSteps + 1; }
                if(hasTeams) { totalSteps = totalSteps + 1; }
                if (hasTestimonials) { totalSteps = totalSteps + 1; }

                setTotalProfileSteps(totalSteps);

                await checkIfUserHasMobileApp();
                let completedSteps = 0;
                if (!isMobileApp && currentUser.hasMobileAppInstalled) {
                    completedSteps = completedSteps + 1;
                }
                if (currentUser.hasAddedMindfulBreak) {
                    completedSteps = completedSteps + 1;
                }
                // TODO: add slack flag to database
                if (hasSlack && currentUser.hasZoomAppInstalled) {
                    completedSteps = completedSteps + 1;
                }
                if (hasZoom && currentUser.hasZoomAppInstalled) {
                    completedSteps = completedSteps + 1;
                }
                if (hasTeams && currentUser.hasTeamsAppInstalled) {
                    completedSteps = completedSteps + 1;
                }
                if (hasTestimonials && currentUser.hasSharedExperience) {
                    completedSteps = completedSteps + 1;
                }

                setProfileStepsCompleted(completedSteps);
            }

        }());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    useEffect(() => {
        if (featureConfig) {
            setHasTestimonials('testimonials' in featureConfig.features && featureConfig.features['testimonials']);
            setHasSlack('slack-app' in featureConfig.features && featureConfig.features['slack-app']);
            setHasZoom('zoom-app' in featureConfig.features && featureConfig.features['zoom-app']);
            setHasTeams('teams-app' in featureConfig.features && featureConfig.features['teams-app']);
        }
    }, [featureConfig]);

    useEffect(() => {
        if (!language) return;

        JourneyApiClient.getAvailableContentTypes()
            .then(contentTypes => {
                setHasBenefitsInThisLocale(contentTypes.hasBenefits);
                setHasDailyJourneyAvailable(contentTypes.hasDailyJourney);
                setHasWpoContent(contentTypes.hasWpoContent);
            });
    }, [language])

    useEffect(() => {
        setHasCompanyCountries(!!currentUser?.company?.countries && currentUser?.company?.countries.length > 0);
    }, [currentUser]);

    useEffect(() => {

        let stateCounter = 1;
        CapApp.addListener('appStateChange', ({ isActive }) => {
            if (!isActive) return;
            setAppResumeCounter(stateCounter++);
        });

        const previouslyChosenLanguageText = localStorage.getItem('language');
        const previouslyChosenLanguage = previouslyChosenLanguageText ? JSON.parse(previouslyChosenLanguageText) as ApiResourcesContentLanguage : null;
        if (!previouslyChosenLanguage?.alpha2CodeISO) {
            changeLanguage(null);
        } else {
            changeLanguage(previouslyChosenLanguage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isAppLoaded) {
            AnalyticsService.trackUserAction("app_resume", null);
        }
    }, [appResumeCounter, isAppLoaded]);

    useEffect(() => {
        if (subscribeToDailyJourney && currentUser) {
            JourneyApiClient.subscribeToDailyJourney()
                .then(() => {
                    handleGeneralMessage(t("Daily Journey"), t("You have subscribed to the Daily Journey! Check your inbox tomorrow for the next installment."));
                    setSubscribeToDailyJourney(false);
                });
        }
    }, [subscribeToDailyJourney, currentUser]);

    useEffect(() => {
        if (needProfileRefresh) {
            (async () => {
                if (currentUser) {
                    await refreshUserData();
                }

                setNeedProfileRefresh(false);
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needProfileRefresh])

    useEffect(() => {
        if (isAuthenticated) {
            (async () => {
                try {
                    const token = await getAccessTokenSilently();
                    JourneyApiClient.setAuthCredentials(token);
                    await refreshUserData();
                } catch (e) {
                    handleGeneralError("Could not save Authentication Credentials", e);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, getAccessTokenSilently]);

    useEffect(() => {
        // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
        // This is only for Android
        CapApp.addListener('appUrlOpen', async ({ url }) => {
            if (url.includes('state') && (url.includes('code') || url.includes('error'))) {
                await handleRedirectCallback(url);
            }
            await Browser.close();
        });
    }, [handleRedirectCallback]);

    useEffect(() => {
        (async () => {
            try {
                setFeatureConfig(await refreshEnabledFeatures());
            } catch (e) {
                handleGeneralError("Could not update enabled features", e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    const initializeCurrentUser = useCallback(async function (): Promise<User | null> {
        const token = JourneyApiClient.getAuthCredentials();
        if (!token) {
            setCurrentUserLoading(false);
            await AnalyticsService.resetUser();
            return null;
        }

        try {
            const newUser = await JourneyApiClient.getCurrentUser();
            await AnalyticsService.identifyUser(newUser);
            setCurrentUser(newUser);
            const previouslyChosenLanguageText = localStorage.getItem('language');
            const previouslyChosenLanguage = previouslyChosenLanguageText ? JSON.parse(previouslyChosenLanguageText) as ApiResourcesContentLanguage : null;
            if (newUser.locale && (!previouslyChosenLanguage || (previouslyChosenLanguage && !previouslyChosenLanguage.hasOwnProperty('isFullySupported')))) {
                changeLanguage(newUser.locale);
            }
            if (newUser.userRoles.indexOf('Sales') > -1 && localStorage.getItem('industryClass')) { setIndustryCssClass(localStorage.getItem('industryClass')!) }
            else { setIndustryCssClass(newUser.company.industryCssClass) }
            setCurrentUserLoading(false);
            setHasDeveloperRole(EnvironmentSwitcherService.canSwitchEnvironments(newUser));
            const features = await refreshEnabledFeatures();
            setFeatureConfig(features);
            await AnalyticsService.setUserHasEAP(features.features['has-eap'] || false);

            return newUser;
        } catch (e) {
            handleGeneralError("Could not fetch user", e);
            setCurrentUserLoading(false);
            await AnalyticsService.resetUser();
            return null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    async function refreshEnabledFeatures(): Promise<FeatureConfig> {
        const features = await JourneyApiClient.getFeatureConfiguration();

        return features;
    }

    // TODO: Throw if there is no currently logged in user
    async function refreshUserData(): Promise<User | null> {

        const token = JourneyApiClient.getAuthCredentials();
        if (!token) {
            setCurrentUserLoading(false);
            return null;
        }

        try {
            setCurrentUserLoading(true);
            const newUser: User = await JourneyApiClient.getCurrentUser();
            setCurrentUser(newUser);
            if (newUser.userRoles.indexOf('Sales') > -1 && localStorage.getItem('industryClass')) { setIndustryCssClass(localStorage.getItem('industryClass')!) }
            else { setIndustryCssClass(newUser.company.industryCssClass) }
            setCurrentUserLoading(false);
            setFeatureConfig(await refreshEnabledFeatures());
            return newUser;
        } catch (e) {
            handleGeneralError("Could not fetch user", e);
            setCurrentUserLoading(false);
            return null;
        }
    }

    async function silentlyRefreshUserData(): Promise<User | null> {
        const token = JourneyApiClient.getAuthCredentials();
        if (token) {
            const newUser: User = await JourneyApiClient.getCurrentUser();
            setCurrentUser(newUser);
            if (newUser.userRoles.indexOf('Sales') > -1 && localStorage.getItem('industryClass')) { setIndustryCssClass(localStorage.getItem('industryClass')!) }
            else { setIndustryCssClass(newUser.company.industryCssClass) }
            setIndustryCssClass(localStorage.getItem('industryClass') ?? newUser.company.industryCssClass);
            return newUser
        }
        return null;
    };
    //TODO use output.data instead of currentUser state variable
    useQuery<Promise<User | null>, unknown, User>(["getSilentlyCurrentUser"], silentlyRefreshUserData);

    function updateDimensions() {
        rafId.current = null;
        setIsMobileWidth(window.innerWidth < 745);
        setIsMobileMenu(window.innerWidth < MOBILE_MENU_TRESHOLD);
    }

    function handleLogout() {
        if (isAuthenticated) {
            logout({ localOnly: true, returnTo: isPlatform('android') ? "com.journeylive.android:MainActivity" : isPlatform('ios') ? 'journeylive:com.journeylive.ios' : window.location.origin });
        }
        AnalyticsService.resetUser();
        JourneyApiClient.logout();
        JourneyApiClient.clearRedirectUrl();
        setCurrentUser(null);
    };

    /**
     * @see JourneyApiClient.errorFormatter
     */
    function handleGeneralError(description: string, error: any, header?: string, errorDataContext?: { contextName: string, contextData: { data: any } }) {

        setIsSuccessMessage(false);

        //Setting Sentry Context for better error reporting, this gives data only for the next error sent.
        if (errorDataContext) {
            Sentry.setContext(errorDataContext.contextName, errorDataContext.contextData);
        }

        if (error.errorCode === 401) {
            setGlobalHeader(t("Please Log In Again"));
            setGlobalMessage(t("Contact info@journey.live if problems persist."));
            setTimeout(() => {
                handleLogout();
            }, 3000);
        } else {
            Sentry.captureException(error);
            if (header) setGlobalHeader(header);
            else setGlobalHeader(t("Application Error"));
            setGlobalMessage(`${t("Please try reloading the page.")} ${t("Contact info@journey.live if problems persist.")}`);
        }
    }

    function handleApplicationError(description: string) {
        let finalError = { errorCode: 500, message: "Application Error" };
        handleGeneralError(description, finalError);
    }

    function handleUserError(header: string, description: string) {
        setGlobalHeader(header);
        setGlobalMessage(description);
        setIsSuccessMessage(false);
        Sentry.captureException(description);
    }

    function handleGeneralMessage(header: string, description: string) {
        setGlobalHeader(header);
        setGlobalMessage(description);
        setIsSuccessMessage(true);
    }

    function resetGlobalMessage() {
        setGlobalHeader(null);
        setGlobalMessage(null);
        setIsSuccessMessage(false);
    }

    async function handleDownloadMobileAppModalDismiss() {
        //Resolves issue for modal not showing on edge case were user opens/closes modal repeatedly in a fast fashion
        await new Promise(r => setTimeout(r, 10));
        setIsDownloadMobileAppModalOpen(false);
    }

    async function handleToggleProfileMenu() {
        let openMenu = !isProfileMenuOpen;
        setIsProfileMenuOpen(openMenu);
        if (openMenu) {
            await AnalyticsService.trackUserAction("profile_menu_opened");
            setIsLanguagesMenuOpen(false);
        }
    }

    const handleToggleOldLanguagesMenu = async () => {
        setIsMiniLanguageMenuOpen(false);
        let openMenu = !isOldLanguagesMenuOpen;
        setIsOldLanguagesMenuOpen(openMenu);
        if (openMenu) {
            await AnalyticsService.trackUserAction("old_languages_menu_opened");
            setIsProfileMenuOpen(false);
        }
    }

    const handleToggleMiniLanguageMenu = async () => {
        setIsOldLanguagesMenuOpen(false);
        let openMenu = !isMiniLanguageMenuOpen;
        setIsMiniLanguageMenuOpen(openMenu);
        if (openMenu) {
            await AnalyticsService.trackUserAction("mini_languages_menu_opened");
            setIsProfileMenuOpen(false);
        }
    }

    const handleToggleLanguagesMenu = async () => {
        let openMenu = !isLanguagesMenuOpen;
        setIsLanguagesMenuOpen(openMenu);
        if (openMenu) {
            await AnalyticsService.trackUserAction("languages_menu_opened");
            setIsProfileMenuOpen(false);
        }
    }

    const handleToggleCountriesMenu = async () => {
        let openMenu = !isCountriesMenuOpen;
        setIsCountriesMenuOpen(openMenu);
        if (openMenu) {
            await AnalyticsService.trackUserAction("countries_menu_opened");
            setIsProfileMenuOpen(false);
        }
    };

    useEffect(() => {
        if (isMindfulBreakModalOpen) {
            AnalyticsService.trackUserAction("view_mindful_break_modal")
                .catch(e => handleGeneralError("Could not open mindfulbreak modal", e));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMindfulBreakModalOpen]);


    function openMindfulBreakModal() {
        setIsMindfulBreakModalOpen(true);
    }

    function openDownloadMobileAppModal() {
        trackDownloadMobileAppModalOpenEvent();
        setIsDownloadMobileAppModalOpen(true);
    }

    function handleHasCompletedAssessment(isCompleted: boolean) {
        setHasCompletedAssessment(isCompleted)
    }

    async function trackDownloadMobileAppModalOpenEvent() {
        await AnalyticsService.trackUserAction("view_download_mobile_app_modal");
    }

    function setUserFlags(flag: string) {
        let user: any = currentUser;
        user[flag] = true;

        setCurrentUser({ ...user });
    }

    function changeLanguage(lang: ApiResourcesContentLanguage | null): void {
        if (!lang) {
            localStorage.removeItem('language');
        } else {
            localStorage.setItem('language', JSON.stringify(lang));
        }
        JourneyApiClient.setLanguage(lang);
        setFullLanguageSupport(lang === null || lang.isFullySupported);
        setLanguage(lang);
        i18n.changeLanguage(lang?.abbreviation ?? 'en-US');
    }

    function setIndustry(industryClass: string) {
        localStorage.setItem('industryClass', industryClass);
        setIndustryCssClass(industryClass);
    }

    const getApplicationContext = (): ApplicationContextType => ({
        currentUser,
        setUserFlags,
        isCurrentUserLoading,
        isMobileWidth: isMobileWidth,
        isMobileMenu: isMobileMenu,
        isMobileApp: isMobileApp,
        appResumeCounter,
        handleLogout,
        refreshUserData,
        silentlyRefreshUserData,
        handleGeneralError,
        handleUserError,
        handleApplicationError,
        handleGeneralMessage,
        openDownloadMobileAppModal,
        isProfileMenuOpen,
        handleToggleProfileMenu,
        isLanguagesMenuOpen,
        handleToggleLanguagesMenu,
        isOldLanguagesMenuOpen,
        handleToggleOldLanguagesMenu,
        isMiniLanguageMenuOpen,
        handleToggleMiniLanguageMenu,
        isCountriesMenuOpen,
        handleToggleCountriesMenu,
        profileStepsCompleted,
        totalProfileSteps,
        hasCompletedAssessment,
        handleHasCompletedAssessment,
        isDownloadMobileAppModalOpen,
        fullLanguageSupport,
        language,
        changeLanguage,
        openMindfulBreakModal,
        isMindfulBreakModalOpen,
        setIsMindfulBreakModalOpen,
        currentlyPlayingId,
        setCurrentlyPlayingId,
        isShareRegistrationLinkOpen,
        setIsShareRegistrationLinkOpen,
        initializeCurrentUser,
        isAppLoaded,
        setIsAppLoaded,
        isInstantHelpSliderOpen,
        setIsInstantHelpSliderOpen,
        isResetInProgress,
        setIsResetInProgress,
        hasDeveloperRole: hasDeveloperRole,
        setHasDeveloperRole: setHasDeveloperRole,
        needProfileRefresh,
        setNeedProfileRefresh,
        featureConfig,
        setFeatureConfig,
        isCommunicationsOptInModalOpen,
        setIsCommunicationsOptInModalOpen,
        subscribeToDailyJourney,
        setSubscribeToDailyJourney,
        industryCssClass: industryCssClass,
        setIndustry,
        setHasBenefitsInThisLocale,
        hasBenefitsInThisLocale,
        hasDailyJourneyAvailable,
        setHasDailyJourneyAvailable,
        hasCompanyCountries,
        setHasCompanyCountries,
        hasWpoContent,
        setHasWpoContent
    });

    //logic should be user ? (user is sales && storage ? storageClass : companyClass ) : cssClass
    return (
        <ApplicationContext.Provider value={getApplicationContext()}>
            <IonApp className={`app-container typography journey-gradient-bg industry-targeting ${currentUser ? (isUserSales && localStorage.getItem('industryClass') ? `${localStorage.getItem('industryClass')}` : currentUser.company.industryCssClass) : `${industryCssClass}`}`}>
                {isResetInProgress && <PleaseWait forefront={true} transparentBackground={true} />}
                <GlobalMessage message={globalMessage} header={globalHeader} onClose={resetGlobalMessage} isSuccessMessage={isSuccessMessage} />
                <IonModal
                    className={"download-mobile-app-modal"}
                    showBackdrop={false}
                    isOpen={isDownloadMobileAppModalOpen}
                    onWillDismiss={handleDownloadMobileAppModalDismiss}>
                    <DownloadMobileApp isComponentVisible={isDownloadMobileAppModalOpen} onExitButtonClick={handleDownloadMobileAppModalDismiss} />
                </IonModal>
                <LoginModalComponent
                    isComponentVisible={isComponentVisible}
                    isModalOpen={isLoginModalOpen}
                    closeModal={() => setIsLoginModalOpen(false)}
                />

                <AppNavigation />
            </IonApp>
        </ApplicationContext.Provider>
    )
};

export default App;
